import './App.css';
import 'antd/dist/antd.css';
import {PlusOutlined, DownOutlined, UploadOutlined, UserOutlined} from '@ant-design/icons';
import {Layout,Button,Form,Input,notification,Select,Upload,message,Table,Spin, Dropdown, Typography, Modal, Switch, Menu, Space, Tabs,  Col, Divider, Row, Radio} from 'antd';
import AWS from 'aws-sdk'
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {useParams } from 'react-router-dom'
import store from 'store2';
import { apiRoot, pubSubRoot } from "./Config";
import * as qs from 'query-string';
import Chart from 'chart.js/auto';
import { Doughnut, Bar } from 'react-chartjs-2';
import { width } from '@fortawesome/free-regular-svg-icons/faAddressBook';
import { render } from '@testing-library/react';
import ReimbursementsHeader from './component/ReimbursementsHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { label } from 'aws-amplify';
import { color } from 'chart.js/helpers';
import moment from 'moment';
import newgenLogo from './newgen_logo.png'

const credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: "us-east-1:086c4450-fa44-46ac-ac22-bcba47a1007a" });
const S3_BUCKET = 'newgen-ae-dev';
const REGION = 'us-east-1';
AWS.config.update({
    region: REGION,
    credentials: credentials
}
);

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

AWS.config.credentials.refresh();
let restFul = axios.create({
    baseURL: apiRoot,
    timeout: 1000
  });
export default function Reimbursements(props) {
    const urlParams = qs.parse(window.location.search);
const { Text } = Typography;
console.log("urlParams", urlParams);
var userAccess = "employee";
var userAuth = store.get("pubkit_auth");
var userRole = store.get("pubkit_role");
var userId = store.get("pubkit_user") == null ? store.get("freelance_user") == null ? null: store.get("freelance_user")["id"]: store.get("pubkit_user")["id"];
if(userId == undefined) window.location.href = window.location.origin
if(userRole !== null){
  if(userRole.includes("reimbursement_manager")) userAccess = "admin";
}

var headers = { ...userAuth };

const [currentTab, setCurrentTab] = useState("bank_details");
var actualValues = [];
var claimedValues = [];

var employeeReimbursementsList = [];
var monthList = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec","Jan", "Feb", "Mar"];

var yearList = [new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear()+1, new Date().getFullYear()+1, new Date().getFullYear()+1]
var monthListFullForm = ["April", "May", "June", "July", "August", "September", "October", "November", "December", "January", "February", "March"];
    // var dataSource = [];
    const [form] = Form.useForm();
    var months = [];
for(const month of monthList){
    months.push({label: month, value: month})
    actualValues.push({month: month, value: 0})
    claimedValues.push({month: month, value: 0})
}
let payload = {"where":{
    "month": months[new Date().getMonth()]["label"],
    "period": new Date().getFullYear() + " - " + (new Date().getFullYear()+1),
    "genre": "Internet"
}}


let adminTableData = [];
let employeeTableData = [];
const [dataSource, setDataSource] = useState([]);
const [updateStatusModalVisible,setUpdateStatusModalVisible] = useState(false);
const [adminSelectedRow, setAdminSelectedRow] = useState();
const [selectedToUpdate, setSelectedToUpdate] = useState("");
const [employeeDocument, setEmployeeDocument] = useState({}); 
const [messageApi, contextHolder] = message.useMessage();
const [uploadedList, setUploadedList] = useState([]);
const [uploadedRow, setUploadedRow] = useState(-1);
const [fileList, setFileList] = useState([]);
const [uploading, setUploading] = useState(false);
var _URL = window.URL || window.webkitURL;
const [bankDetails, setBankDetails] = useState({});
const [userAppFuzzy, setUserAppFuzzy] = useState(null)
const { Header,Content } = Layout;
useEffect(()=>{
  console.log("userId", userId)
                   axios.post(pubSubRoot + `reportsmicro/reports/approved_queries/bank-details-by-user`, {where: {}}, {headers})
                      .then(res => {
                          console.log(res.data[0]['fuzzy_id']);
                          if(res.data.length>0){
                            setUserAppFuzzy(res.data[0]['fuzzy_id'])
                            setBankDetails({
                              user: res.data[0]["full_name"],
                              bank_account_name: res.data[0]["bank_account_name"],
                              bank_name: res.data[0]["bank_name"],
                              bank_branch: res.data[0]["bank_branch"],
                              bank_account_number: res.data[0]["bank_account_number"],
                              pan_number: res.data[0]["pan_number"],
                              ifsc_rtgs_code: res.data[0]["ifsc_rtgs_code"],
                              fuzzy_id: res.data[0]['fuzzy_id']
                          })
                          form.setFieldsValue({
                            user: res.data[0]["full_name"],
                            bank_account_name: res.data[0]["bank_account_name"],
                            bank_name: res.data[0]["bank_name"],
                            bank_branch: res.data[0]["bank_branch"],
                            bank_account_number: res.data[0]["bank_account_number"],
                            pan_number: res.data[0]["pan_number"],
                            ifsc_rtgs_code: res.data[0]["ifsc_rtgs_code"]
                        })
                        if(userId == undefined) window.location.href = window.location.origin
                          }
                          
                      }).catch(function (error) {
                          console.log(error);
                      });  
                    
                
                
  }, [])

  const updateBankDetails = (e)=> {
    console.log(e)
    console.log(store.get("freelance_user"))
    let userDetails = store.get("pubkit_user") == null ? store.get("freelance_user"): store.get("pubkit_user")
    let payload = {
      "status": "SUBMITTED",
      "user_id": userId,
      "company": "NKW",
      "country": "India",
      "fuzzy_id": userAppFuzzy,
      "first_name": userDetails.first_name == null ? userDetails.full_name: userDetails.first_name,
      "last_name": userDetails.last_name == null ? userDetails.full_name: userDetails.last_name,
      "bank_account_name": e.bank_account_name,
      "bank_account_number": e.bank_account_number,
      "bank_name": e.bank_name,
      "ifsc_rtgs_code": e.ifsc_rtgs_code,
      "pan_number": e.pan_number,
      "bank_branch": e.bank_branch,
      "user_type": "EMPLOYEE"
  }
    axios.post(apiRoot + `submissions/freelancer/api/bank-details/upsert`, payload, {headers})
                      .then(res => {
                          console.log(res.data);
                          messageApi.success("Bank Details Updated")
                      }).catch(function (error) {
                          console.log(error);
                          messageApi.error("Submission Failed")
                      });  
  }
  const menu = (
    <Menu>
      <Menu.Item key='1' onClick={()=> {window.location.href = window.location.href+"/preferences"}}>
        Preferences
      </Menu.Item>
      <Menu.Item key='2' onClick={()=> {window.location.href = window.location.href}}>
        Logout
      </Menu.Item>
    </Menu>
  );
    console.log("userAccess",userAccess)
        return (   
            <Layout>
                {contextHolder}
              <Header
                className="site-layout-background"
                style={{
                  padding: 0,
                }}
              >  
                <div>
                  <img src={newgenLogo} style={{maxHeight:"40px", marginLeft:"20px"}}>
                  </img>
                    <span style={{color:"white", fontSize:"20px", marginLeft:"20px"}}>Miscellaneous</span>
                
                <Dropdown  overlay={menu} style={{color: "white",fontSize: "20px", marginLeft: "auto"}}>
                  <Button style={{background: "none", border: "none", fontSize: "20px", float: "right", marginTop: "5px"}}>
                  <UserOutlined style={{color: "white" }}>
                    </UserOutlined>
                  </Button>
                </Dropdown>
                </div>
                
              </Header>
              <Layout className="site-layout">
              
              <Content
                  className="site-layout-background"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "24px 16px",
                    padding: 24,
                    minHeight: "100vh",
                  }}
                >
                  
                  <div className="domainmail-continer" style={{ width: "100%" }}>
                  <h2>Preferences</h2>
                    <div>
                    <Radio.Group
                      block
                      options={[{
                        label: 'Bank Details',
                        value: 'bank_details',
                      },{
                        label: 'Profile Settings',
                        value: 'profile_settings',
                      }]}
                      defaultValue="bank_details"
                      optionType="button"
                      buttonStyle="solid"
                      onChange={e=> {
                        console.log(e.target.value);
                        setCurrentTab(e.target.value)
                      }}
                    />
                    </div>
                    {currentTab === "bank_details" ? <div>
                    {bankDetails.user!==undefined? <div> <h5 style={{padding: "20px 0"}}>Current Bank Details:</h5>
                    <Row justify="space-evenly" style={{marginBottom:"50px"}}>
                    <Col style={{borderRight: "1px solid black", textAlign:"center"}} span={4}>Your Name in Bank Account<br></br><span style={{marginBottom: "20px", fontWeight: "700"}} >{bankDetails.bank_account_name}</span> </Col>
                    <Col style={{borderRight: "1px solid black", textAlign:"center"}} span={4}>Bank Name<br></br><span style={{marginBottom: "20px", fontWeight: "700"}} >{bankDetails.bank_name}</span> </Col>
                    <Col style={{borderRight: "1px solid black", textAlign:"center"}} span={4}>Bank Branch<br></br><span style={{marginBottom: "20px", fontWeight: "700"}} >{bankDetails.bank_branch}</span></Col>
                    <Col style={{borderRight: "1px solid black", textAlign:"center"}} span={4}>Bank Account No<br></br><span style={{marginBottom: "20px", fontWeight: "700"}} >{bankDetails.bank_account_number}</span></Col>
                    <Col style={{borderRight: "1px solid black", textAlign:"center"}} span={4}>IFSC No<br></br><span style={{marginBottom: "20px", fontWeight: "700"}} >{bankDetails.ifsc_rtgs_code}</span></Col>
                    <Col style={{textAlign:"center"}} span={4}>PAN Number<br></br>{bankDetails.pan_number}</Col>
                    </Row> </div>: <h5 style={{padding: "50px"}}>No Bank Details submitted.</h5> }
                    
    
                    <h5>Update Bank Details:</h5>
                    <Form form={form} layout="vertical" name="searchForm" onFinish={updateBankDetails}>
                  <div style={{display:"flex", flexDirection: "column", width: "50%"}}>
                  <div class="reimbursement-category">
                        <label>Your Name in Bank Account:</label>
                        <Form.Item name="bank_account_name" rules={[{required: true}]}>
                        <Input></Input>
                        </Form.Item>
                        
                        </div>
                        <div class="reimbursement-category">
                        <label>Bank Name:</label>
                        <Form.Item name="bank_name" rules={[{required: true}]}>
                        <Input></Input>
                        </Form.Item>
                        
                        </div>
                        <div class="reimbursement-category">
                        <label>Bank Branch:</label>
                        <Form.Item name="bank_branch" rules={[{required: true}]}>
                        <Input/>
                        </Form.Item>
                        </div>
                        <div class="reimbursement-category">
                        <label>Bank Account No:</label>
                        <Form.Item name="bank_account_number" rules={[{required: true}]}>
                        <Input></Input>
                        </Form.Item>
                        
                        </div>
                        <div class="reimbursement-category">
                        <label>PAN Number:</label>
                        <Form.Item name="pan_number" rules={[{required: true}]}>
                        <Input></Input>
                        </Form.Item>
                        
                        </div>
                        <div class="reimbursement-category">
                        <label>IFSC Code:</label>
                        <Form.Item name="ifsc_rtgs_code" rules={[{required: true}]}>
                        <Input></Input>
                        </Form.Item>
                        
                        </div>
                        <div class="reimbursement-category">
                        <Button htmlType="submit" type='primary' style={{backgroundColor: "#F68732", border: "none", width: "50%", alignSelf: "center"}}>Update Bank Details</Button>
                        </div>
                        
                    </div>
                  </Form>    
                    </div>: <h2 style={{padding: "50px"}}>Under development</h2>}
                    
                      
                  </div>
                  
                  </Content>
              </Layout>
              
            </Layout>
          );
    
    
    
};