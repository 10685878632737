import './App.css';
import 'antd/dist/antd.css';
import {PlusOutlined, DownOutlined, UploadOutlined, UserOutlined} from '@ant-design/icons';
import {Layout,Button,Form,Input,notification,Select,Upload,message,Table,Spin, Dropdown, Typography, Modal, Switch, Menu, Space} from 'antd';
import AWS from 'aws-sdk'
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {useParams } from 'react-router-dom'
import store from 'store2';
import { apiRoot, pubSubRoot } from "./Config";
import * as qs from 'query-string';
import Chart from 'chart.js/auto';
import { Doughnut, Bar } from 'react-chartjs-2';
import { width } from '@fortawesome/free-regular-svg-icons/faAddressBook';
import { render } from '@testing-library/react';
import ReimbursementsHeader from './component/ReimbursementsHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { label } from 'aws-amplify';
import { color } from 'chart.js/helpers';
import moment from 'moment';
import newgenLogo from './newgen_logo.png'

const credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: "us-east-1:086c4450-fa44-46ac-ac22-bcba47a1007a" });
const S3_BUCKET = 'newgen-ae-dev';
const REGION = 'us-east-1';
AWS.config.update({
    region: REGION,
    credentials: credentials,
    correctClockSkew: false
}
);

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

AWS.config.credentials.refresh();
let restFul = axios.create({
    baseURL: apiRoot,
    timeout: 1000
  });
export default function Reimbursements(props) {
    const urlParams = qs.parse(window.location.search);
const { Text } = Typography;
console.log("urlParams", urlParams);
var userAccess = "employee";
var userAuth = store.get("pubkit_auth");
var userRole = store.get("pubkit_role");
var userId = store.get("pubkit_user") == null ? store.get("freelance_user") == null ? null: store.get("freelance_user")["id"]: store.get("pubkit_user")["id"];
if(userId == undefined) window.location.href = window.location.origin
if(userRole !== null){
  if(userRole.includes("reimbursement_manager")) userAccess = "admin";
  var headers = { ...userAuth };
}


const [actualFinalValues, setActualValues] = useState([]);
const [claimedFinalValues, setClaimedValues] = useState([]);

var actualValues = [];
var claimedValues = [];

var employeeReimbursementsList = [];
var monthListRegular = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
var monthList = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec","Jan", "Feb", "Mar"];

var yearList = [new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear(), new Date().getFullYear()+1, new Date().getFullYear()+1, new Date().getFullYear()+1]
var monthListFullForm = ["April", "May", "June", "July", "August", "September", "October", "November", "December", "January", "February", "March"];
    // var dataSource = [];
    const [form] = Form.useForm();
    var months = [];
for(const month of monthList){
    months.push({label: month, value: month})
    actualValues.push({month: month, value: 0})
    claimedValues.push({month: month, value: 0})
}
let payload = {"where":{
    "month": monthListRegular[new Date().getMonth()],
    "period": (new Date().getFullYear() -1) + "-" + new Date().getFullYear(),
    "genre": "Internet"
}}


let adminTableData = [];
let employeeTableData = [];
const [dataSource, setDataSource] = useState([]);
const [transferDataSource, setTransferDataSource] = useState([]);
const [updateStatusModalVisible,setUpdateStatusModalVisible] = useState(false);
const [adminSelectedRow, setAdminSelectedRow] = useState();
const [selectedToUpdate, setSelectedToUpdate] = useState("");
const [employeeDocument, setEmployeeDocument] = useState({}); 
const [messageApi, contextHolder] = message.useMessage();
const [uploadedList, setUploadedList] = useState([]);
const [hasBankDetails, setHasBankDetails] = useState(true);
const [uploadedRow, setUploadedRow] = useState(-1);
const [fileList, setFileList] = useState([]);
const [uploading, setUploading] = useState(false);
const [isModalOpen, setIsModalOpen] = useState(false);
const showModal = () => {
  setIsModalOpen(true);
};
const handleOk = () => {
  setIsModalOpen(false);
};
const handleCancel = () => {
  setIsModalOpen(false);
};
var _URL = window.URL || window.webkitURL;
function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
  );
}
const propss = {
    onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
    beforeUpload: (file) => {
      
      var objectUrl = _URL.createObjectURL(file);
      setEmployeeDocument(file);
      setFileList([...fileList, file]);
      
    },

    
  };
// var claimedValues = [1750,1800,2100,3100,1500,600,900,1200,2000,2100,2000, 1750];
// var actualValues = [1250,2000,2200,3200,1200,500,800,1900,2100,2200,2100,1850];
// var employeeReimbursementsList = [{name: "Anirudh A", id: 90210}, {name:"Saravanan K", id: 90210}, {name: "Arun Prasad", id: 90210}, {name:"Veerachamy A", id: 90210},{name: "Arun Prasad", id: 90210}, {name:"Veerachamy A", id: 90210},{name: "Arun Prasad", id: 90210}, {name:"Veerachamy A", id: 90210},{name: "Arun Prasad", id: 90210}, {name:"Veerachamy A", id: 90210},{name: "Arun Prasad", id: 90210}, {name:"Veerachamy A", id: 90210},{name: "Arun Prasad", id: 90210}, {name:"Veerachamy A", id: 90210}]
useEffect(()=>{
  
  console.log("month index", monthListRegular[new Date().getMonth()])
    const apiCalls = async ()=> {
        if(userAccess == "employee" || urlParams["type"] == "employee"){
            await axios.post(pubSubRoot + `reportsmicro/reports/approved_queries/reimbursements-by-user`, {"where":{
                "period": urlParams["year"]? urlParams["year"] :(new Date().getFullYear() -1) + "-" + new Date().getFullYear(),
                "genre": "Internet"
            }}, {headers})
                .then(res => {
                    console.log(res.data);
                    let data = res.data;
                    for(const reimbursement of data){
                        employeeReimbursementsList.push({name: reimbursement["full_name"], id: reimbursement["user_id"], actual: reimbursement["actual"], claim:Number(reimbursement["claim"]), status:reimbursement["status"], month: reimbursement["month"], year: reimbursement["year"], fuzzy_id: reimbursement["fuzzy_id"], file_path: reimbursement["file_path"], period: (new Date().getFullYear() -1) + "-" + new Date().getFullYear(), claimedOn: reimbursement["created_at"] })
                        let objIndex = actualValues.findIndex(obj => obj.month == reimbursement["month"]);
                        actualValues[objIndex].value = Number(reimbursement["actual"]); 
                        objIndex = claimedValues.findIndex(obj => obj.month == reimbursement["month"]);
                        claimedValues[objIndex].value = Number(reimbursement["claim"]);
                            
                            console.log(actualValues)
                            setActualValues(actualValues);
                            setClaimedValues(claimedValues);
                    }
                    console.log(employeeReimbursementsList)
                    for (const [index, value] of employeeReimbursementsList.entries()){
                        employeeTableData.push({key: index,employeeName: value["full_name"], employeeID: userId ,month: value["month"],monthFull: monthListFullForm[monthList.findIndex((e)=> e== value["month"])], claim: value["claim"], actual: value["actual"], status: value["status"], download: value["file_path"], year: value["year"], fuzzy_id: value["fuzzy_id"],period:(new Date().getFullYear() -1) + "-" + new Date().getFullYear(), claimedOn: moment(value["claimedOn"]).format("lll")})
                        }
                    for(const [index, month] of monthList.entries()){
                        if(employeeTableData.map((filObj) => filObj.month).includes(month)) continue;
                        employeeTableData.push({key: index+ res.data.length,employeeID: userId  ,month: month, claim: 0, actual: 0, status: "Apply", download: "",monthFull: monthListFullForm[monthList.findIndex((e)=> e== month)], year: Number(yearList[monthList.findIndex((e)=> e== month)])- (new Date().getFullYear() - (new Date().getFullYear() -1)), period: (new Date().getFullYear() -1) + "-" + new Date().getFullYear()})
                    }
                    employeeTableData.sort((a, b) => (monthList.findIndex((e)=> e == a.month)) - monthList.findIndex((e)=> e == b.month))
                    setDataSource(employeeTableData)
                    console.log(employeeTableData)
                    
                }).catch(function (error) {
                    console.log(error);
                });
                await axios.post(pubSubRoot + `reportsmicro/reports/approved_queries/bank-details-by-user`, {"where":{
                  "period": urlParams["year"]? urlParams["year"] :(new Date().getFullYear() -1) + "-" + new Date().getFullYear(),
                  "genre": "Internet"
              }}, {headers}).then(res => {
                  if(res.data.length< 1){
                    message.error("We seem to be missing your bank details. Please add them in the Preferences page");
                    setHasBankDetails(false)
                  } 
                  else if(res.data[0].bank_name == null){
                    setHasBankDetails(false)
                    message.error("We seem to be missing your bank details. Please add them in the Preferences page");
                  } 
              }).catch(function (error) {
                console.log(error);
            });
        }
        
            if(userAccess === "admin" && urlParams["type"] !== "employee"){
                await axios.post(pubSubRoot + `reportsmicro/reports/approved_queries/reimbursements-by-month`, payload, {headers})
                    .then(res => {
                        console.log(res.data);
                        for(const reimbursement of res.data){
                            employeeReimbursementsList.push({name: reimbursement["full_name"], id: reimbursement["user_id"], actual: reimbursement["actual"], claim:reimbursement["claim"], status:reimbursement["status"], month: reimbursement["month"],monthFull: monthListFullForm[monthList.findIndex((e)=> e== reimbursement["month"])] ,year: reimbursement["year"], fuzzy_id: reimbursement["fuzzy_id"], file_path: reimbursement["file_path"]})
                            let objIndex = actualValues.findIndex(obj => obj.month == reimbursement["month"]);
                            actualValues[objIndex].value = actualValues[objIndex].value + Number(reimbursement["actual"]);
                            objIndex = claimedValues.findIndex(obj => obj.month == reimbursement["month"]);
                            claimedValues[objIndex].value = claimedValues[objIndex].value + Number(reimbursement["claim"]);
                            
                            setDataSource(adminTableData);
                            setActualValues(actualValues);
                            setClaimedValues(claimedValues);
                            reimbursementTransfers();
                        }
                        for (const [index, value] of employeeReimbursementsList.entries()){
                            adminTableData.push({key: index,employeeName: value["name"],year: value["year"] ,employeeID: value["id"], month: value["month"], monthFull: monthListFullForm[monthList.findIndex((e)=> e== value["month"])],claim: value["claim"], actual: value["actual"], status:  value["status"], download: value["file_path"], fuzzy_id: value["fuzzy_id"]})        
                        }
                        console.log(adminTableData)
                    }).catch(function (error) {
                        console.log(error);
                    });  
            }
    }
    apiCalls();
    
}, [])


    
const updateActualError = () => {
    messageApi.error("Please enter the amount of your invoice under the 'Claimed' Column before uploading the invoice");
  };

      
function add(accumulator, a) {
    return accumulator + a;
  }

function reRender(e){
    console.log(e)
    window.location.href = "reimbursements?page=self&type=employee"
}
function reRenderAdmin(e){
    console.log(e)
    window.location.href = "reimbursements?page=self"
}

function reimbursementTransfers(){
  axios.post(apiRoot + `reportsmicro/reports/approved_queries/reimbursement-transfers`, payload, {headers}).then(res => {
    console.log(res.data);
    setTransferDataSource(res.data);
  })
}
function downloadCSV(e){
  let payloadDetails = dataSource;
  console.log(payloadDetails);
  let payload = {
    "year": payloadDetails[0]["year"],
    "month": payloadDetails[0]["month"],
    "hdfc_bank": e,
    "status": "Approved"
}

  axios.post(apiRoot + `office/api/accounts/reimbursements/monthly-claim-download-csv`,payload,{headers: headers}).then(res => {
    
    console.log(res.data)
    const url = window.URL.createObjectURL(new Blob([res.data])) 
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${payloadDetails[0]["year"]}-${payloadDetails[0]["month"]}-${e?"HDFC":"Non-HDFC"}Bank-CSV.csv`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      message.success(`${payloadDetails[0]["year"]}-${payloadDetails[0]["month"]} CSV Downloaded`)
}).catch(err => {
  console.log(err);
  message.error("Something went wrong, Please try again!")
  return
});
}
var xValues = ["Total Actual", "Total Claimed"];
var yValues = [actualFinalValues.map((filObj) => filObj.value).reduce(add, 0), claimedFinalValues.map((filObj) => filObj.value).reduce(add, 0)];

var barColors = [
    "#febd2b",
    "#5470c6"
];
const { Header,Content } = Layout;

function updateStatusforApplication(e){
    if(hasBankDetails == false){
      message.error("We seem to be missing your bank details. Please add them in the Preferences page");
      return
    }
    
    console.log(adminSelectedRow)
    console.log(dataSource);
    let insertPayload = {
          "user_id": adminSelectedRow["employeeID"],
          "claim": adminSelectedRow["claim"],
          "actual": adminSelectedRow["actual"],
          "file_path": adminSelectedRow["download"],
          "month": adminSelectedRow["month"],
          "year": adminSelectedRow["year"],
          "genre": "Internet",
          "file_name": ``,
          "fuzzy_id":adminSelectedRow["fuzzy_id"],
          "status":adminSelectedRow["new_status"] !== "Rejected" ? "Approved": adminSelectedRow["new_status"]
    }
    
      axios.post(apiRoot + `office/api/accounts/reimbursements/admin/${adminSelectedRow["fuzzy_id"]}`, insertPayload, {headers: headers}).then(res => {

        console.log(res.data)
        
    }).catch(err => {
      console.log(err);
      message.error("Something went wrong, Please try again!")
      return
    });
    
    message.success("Submitted successfully!")
}

async function uploadInvoice(e, index){
  if(hasBankDetails == false){
    message.error(`We seem to be missing your bank details. Please add them at https://misc.newgen.co/user/preferences`);
    return
  }
    
    console.log(e);
    var invoiceFuzzyID = uuidv4();
    if(e.fuzzy_id !== undefined){
      invoiceFuzzyID = e.fuzzy_id
    } 
    
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file);
    });
    
    console.log(employeeDocument, typeof employeeDocument)
    let fileExtension = employeeDocument.name.split(".");
    fileExtension = fileExtension[fileExtension.length-1]
    console.log(fileExtension)
    if(uploadedList.length< 1){
        messageApi.error("No invoice attached");
        return
    }
    
    if(e.new_claimed < 1 || e.new_claimed == undefined && e.claim < 1) {
        updateActualError();
        return
    }
    messageApi.info("Uploading...")   
    console.log(new Date())
    console.log(invoiceFuzzyID)
    AWS.util.getDate = function() { // adjust for clock skew
      return new Date(new Date().getTime() + 3600000);
    };
    var uploadParams = {Key: `uploads/reimbursements/internet/${e.year}/${e.month}/Reimbursement-${invoiceFuzzyID}-${userId}.${fileExtension}`, Body: employeeDocument};
    myBucket.upload(uploadParams, function (err, data) {
    
        if (err) {

          console.log("Error", typeof err);
          AWS.events.on('retry', function (response) {

              console.log(response.httpResponse.headers)
              var serverTime = Date.parse(response.httpResponse.headers);
              var timeNow = new Date().getTime();
              AWS.config.systemClockOffset = Math.abs(timeNow - serverTime);
              response.error.retryable = true;
            
          });
          message.error(err.name)
          return
        } if (data) {
            setFileList([]);
          console.log("Upload Success", data);
          let insertPayload = {
            "user_id": userId,
            "claim": Number(e.new_claimed)< 1 || e.new_claimed == undefined ? e.claim: Number(e.new_claimed),
            "actual": e.actual,
            "file_path": `uploads/reimbursements/internet/${e.year}/${e.month}/Reimbursement-${invoiceFuzzyID}-${userId}.${fileExtension}`,
            "month": e.month,
            "year": e.year,
            "period": e.period.replaceAll(" ", ""),
            "genre": "Internet",
            "file_name": `Reimbursement-${invoiceFuzzyID}-${userId}.${fileExtension}`,
            "fuzzy_id":e.fuzzy_id!== undefined? e.fuzzy_id: invoiceFuzzyID
            
          } 
          console.log(insertPayload)
         
          axios.post(apiRoot + `office/api/accounts/reimbursements/employee/${invoiceFuzzyID}`, insertPayload, {headers: headers}).then(res => {

            console.log(res.data)
            message.success("Submitted successfully!")
            setTimeout(function(){
              window.location.reload();
           }, 3000);  
        }).catch(err => {
          console.log(err);
          message.error(err.response.data.message);
          message.error("Submission failed");
          return
        }).finally(()=> {
            setUploading(false); 
        });
        }
        else{
          message.error("Upload unsuccessful, Please try again!")
          return
        }
      });
}

function cancelUpdateModal(){
    setUpdateStatusModalVisible(false)
}



async function searchReimbursements(e) {
    console.log(e);
    messageApi.info(`Loading ${e.year} Reimbursements...`)
    if(userAccess == "employee" || urlParams["type"] == "employee"){
      setDataSource(null)
        await axios.post(pubSubRoot + `reportsmicro/reports/approved_queries/reimbursements-by-user`, {"where":{
            "period": urlParams["year"]? urlParams["year"] :e.year+ "",
            "genre": e.genre
        }}, {headers})
            .then(res => {
                console.log(res.data);
                employeeTableData = [];
                console.log(employeeTableData);
                for (const [index, value] of res.data.entries()){
                    employeeTableData.push({key: index,employeeName: value["full_name"], employeeID: userId ,month: value["month"],monthFull: monthListFullForm[monthList.findIndex((e)=> e== value["month"])], claim: value["claim"], actual: value["actual"], status: value["status"], download: value["file_path"], year: value["year"], fuzzy_id: value["fuzzy_id"], period: e.year})
                    }
                for(const [index, month] of monthList.entries()){
                    if(employeeTableData.map((filObj) => filObj.month).includes(month)) continue;
                    employeeTableData.push({key: index+ res.data.length,employeeID: userId  ,month: month, monthFull: monthListFullForm[monthList.findIndex((e)=> e== month)],claim: 0, actual: 0, status: "Apply", download: "", year: Number(yearList[monthList.findIndex((e)=> e== month)])- (new Date().getFullYear() - Number(e.year.split("-")[0])), period: e.year})
                }
                employeeTableData.sort((a, b) => (monthList.findIndex((e)=> e == a.month)) - monthList.findIndex((e)=> e == b.month))
                setDataSource(employeeTableData)
                console.log(employeeTableData)
                for(const reimbursement of res.data){
                  employeeReimbursementsList.push({name: reimbursement["full_name"], id: reimbursement["user_id"], actual: reimbursement["actual"],monthFull: monthListFullForm[monthList.findIndex((e)=> e== reimbursement["month"])], claim:reimbursement["claim"], status:reimbursement["status"], month: reimbursement["month"], year: reimbursement["year"], fuzzy_id: reimbursement["fuzzy_id"], file_path: reimbursement["file_path"], period: e.year})
                  let objIndex = actualValues.findIndex(obj => obj.month == reimbursement["month"]);
                  actualValues[objIndex].value = Number(reimbursement["actual"]); 
                  objIndex = claimedValues.findIndex(obj => obj.month == reimbursement["month"]);
                  claimedValues[objIndex].value = Number(reimbursement["claim"]);
                      
                      console.log(actualValues)
                      
              }
              setActualValues(actualValues);
              setClaimedValues(claimedValues);
            }).catch(function (error) {
                console.log(error);
            });
    }
    
        if(userAccess === "admin" && urlParams["type"] !== "employee"){
            payload.where.month = e.month
            payload.where.period = e.year.replaceAll(" ", "")
            await axios.post(pubSubRoot + `reportsmicro/reports/approved_queries/reimbursements-by-month`, payload, {headers})
                .then(res => {
                    console.log(res.data);
                    for(const reimbursement of res.data){
                        employeeReimbursementsList.push({name: reimbursement["full_name"], id: reimbursement["user_id"], actual: reimbursement["actual"], claim:reimbursement["claim"], status:reimbursement["status"], month: reimbursement["month"], year: reimbursement["year"], fuzzy_id: reimbursement["fuzzy_id"], file_path: reimbursement["file_path"], period: e.year})
                        let objIndex = actualValues.findIndex(obj => obj.month == reimbursement["month"]);
                        actualValues[objIndex].value = actualValues[objIndex].value+ Number(reimbursement["actual"]);
                        objIndex = claimedValues.findIndex(obj => obj.month == reimbursement["month"]);
                        claimedValues[objIndex].value = claimedValues[objIndex].value + Number(reimbursement["claim"]);
                        
                        
                    }
                    
                    for (const [index, value] of employeeReimbursementsList.entries()){
                        adminTableData.push({key: index,employeeName: value["name"], employeeID: value["id"], month: value["month"],year: e.year ,claim: value["claim"], actual: value["actual"], status:  value["status"], download: value["file_path"], fuzzy_id: value["fuzzy_id"], period: e.year})        
                    }
                    setDataSource(adminTableData);
                    setActualValues(actualValues);
                    setClaimedValues(claimedValues);
                    console.log(adminTableData)
                }).catch(function (error) {
                    console.log(error);
                });  
        }
    // if(e.month) window.location.href = `reimbursements?page=self&type=employee&year=${e.year}&month=${e.month}` 
    // window.location.href = `reimbursements?page=self&type=employee&year=${e.year}` 
  }
  var doughData = {
    labels: xValues,
    datasets: [{
      backgroundColor: barColors,
      data: yValues
    }],
    
}

function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

function fetchDownload(e){
  axios.post(pubSubRoot + `office/api/common/downloadable-url`, { "file_path": e }, {headers})
                .then(res => {
                    console.log(res.data);
                    if (res.data["status"] == 200) {
                      window.open(res.data.url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
                    }
                    
                }).catch(function (error) {
                    console.log(error);
                });  
}
function markAsPaid(e){
  console.log(e)
  axios.put(pubSubRoot + `office/api/common/user-activity-logs/${e.id}`, {}, {headers})
                .then(res => {
                    console.log(res.data);
                    
                }).catch(function (error) {
                    console.log(error);
                });  
}
function dateCheck(from,to,check) {

    var fDate,lDate,cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);

    if((cDate <= lDate && cDate >= fDate)) {
        return true;
    }
    return false;
}
var barData = {
labels: monthList,
datasets: [{
  label: "Actual",
  backgroundColor: barColors[0],
  data: actualFinalValues?.map((filObj) => filObj.value)
},
{
    label: "Claimed",
    backgroundColor: barColors[1],
    data: claimedFinalValues?.map((filObj) => filObj.value)
  }],
options: {
    responsive: true,
    aspectRatio:4 
}
}

const transferColumns = [
  {
    title: 'S/N',
    key:"index",
    render:(value, item, index) =>{ return index+1}
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    },
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
      },
    {
      title: 'Created At',
      dataIndex: 'created',
      key: 'created',
      },
      {
        title: 'Action',
        dataIndex: 'paid',
        key: 'paid',
        render: (e, row)=> {
        return (
            <div>
                <Button onClick={(e)=> markAsPaid(row)}>Paid</Button>
            </div>
            
        )
        }
        },
]
const adminColumns = [
{
  title: 'S/N',
  key:"index",
  render:(value, item, index) =>{ return index+1}
},
{
title: 'Emp Name',
dataIndex: 'employeeName',
key: 'employeeName',
},
{
title: 'Emp ID',
dataIndex: 'employeeID',
key: 'employeeID',
},
{
title: 'Claimed',
dataIndex: 'claim',
key: 'claim',
},
{
title: 'Actual',
dataIndex: 'actual',
key: 'actual'
},
{
  title: 'Status',
  dataIndex: 'status',
  key: 'status'
},
{
title: 'Action',
dataIndex: 'status',
key: 'status',
render: (e, row)=> {
return (
    <div>
        <Select defaultValue={row.status} style={{width:"100%"}} onChange={(e)=> {console.log(e, row); setSelectedToUpdate(`You are changing status for ${row.employeeName}'s reimbursement for ${row.month} to ${e}`); row.new_status = e;setAdminSelectedRow(row);setUpdateStatusModalVisible(true) }}>
        <options value="Approved" style={{color:"green"}}>Approved</options>
        <options value="Rejected" style={{color:"red"}}>Rejected</options>
        <options value="In review" style={{color:"orange"}}>In review</options>
    </Select>
    </div>
    
)
}
},
{
title: 'Receipt',
dataIndex: 'download',
key: 'download',
render: (e, row)=> {
  return <div style={{color: "#fc7008", fontWeight: 600}} onClick={()=> {fetchDownload(row.download)}}><a><FontAwesomeIcon icon={faDownload} />  Download</a></div>
}
},];
var columns = [
  {
    title: 'S/N',
    key:"index",
    render:(value, item, index) =>{ return index+1}
  },
{
title: 'Month',
dataIndex: 'monthFull',
key: 'monthFull',
render:(e, row)=> {
    return `${row["monthFull"]} ${row["year"]}`
}
},
{
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: "220px",
    render: (e, row, index)=> {
        //if(row.status == "Apply") return <div><Button style={{color: "green"}} onClick={()=> {uploadInvoice(row, index)}}>Apply</Button></div>
        return <div style={{fontWeight: 600}}>{row.status == null ? "In review": row.status}</div>
        // if(row.status == "Apply") return <div style={{color: "green"}} >Not Applied</div>
        // if(row.status == "In Review") return <div> <span style={{color: "orange", fontWeight: 600}}>In review</span></div>
        // if(row.status == "Paid") return <div style={{color: "green", fontWeight: 600}}>Paid</div>
        // return <div style={{color: "orange", fontWeight: 600}}>{row.status}</div>
    }
    },
{
    title: 'Actual',
    dataIndex: 'actual',
    key: 'actual',
    width: "200px",
    },
{
title: 'Claimed',
dataIndex: 'claim',
key: 'claim',
width: "200px",
render: (e, row)=> {
    return <div style={{width: "100%"}}><Input type='number' defaultValue={Number(e)} onChange={(s)=> {row.new_claimed = s.target.value;}}></Input></div>
}
},
{
  title: 'Claimed On',
  dataIndex: 'claimedOn',
  key: 'claimedOn',
  width: "200px"
},
{
    title: 'Upload Invoice',
    dataIndex: 'upload',
    key: 'upload',
    width: "200px",
    render: (e, row, index)=> {
        if(row.status !== "Paid" && row.status !== "Approved") return <div style={{display: "flex", flexDirection: "row"}}>
      <Upload {...propss} onChange={(info) => {
        setUploadedList(info.fileList)
        setUploadedRow(index);
      console.log(index);
    }}>
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload>
      <Button
        type="primary"
        onClick={()=> {uploadInvoice(row, index)}}
        disabled={fileList.length ===0 || uploadedRow!== index}
        loading={uploading}
        
      >
        {uploading ? 'Uploading' : 'Submit'}
      </Button></div>
        return <div></div>
    }
},
{
    title: 'Receipt',
    dataIndex: 'download',
    key: 'download',
    width: "200px",
    render: (e, row)=> {
        if(row.status == "Paid" || row.status == "In review") return <div style={{color: "#fc7008", fontWeight: 600}}><a onClick={()=> fetchDownload(row.download)}><FontAwesomeIcon icon={faDownload} />  Download</a></div>
        if(row.status == "Apply") return <div></div>
    }
    },
];
const items = [
  {
    key: '1',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
        1st menu item
      </a>
    ),
  },
  {
    key: '2',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
        2nd menu item (disabled)
      </a>
    ),
    icon: <UserOutlined />,
    disabled: true,
  }]
  const menu = (
    <Menu>
      <Menu.Item key='1' onClick={()=> {window.location.href = window.location.origin +"/user/preferences"}}>
        Preferences
      </Menu.Item>
      <Menu.Item key='2' onClick={()=> {window.location.href = window.location.origin}}>
        Logout
      </Menu.Item>
    </Menu>
  );
    console.log("userAccess",userAccess)
    if(userAccess !== "admin" || urlParams["type"] == "employee"){
        return (   
            <Layout>
                {contextHolder}
              <Header
                className="site-layout-background"
                style={{
                  padding: 0,
                }}
              >  
                 
                <div>
                  <img src={newgenLogo} style={{maxHeight:"40px", marginLeft:"20px"}}>
                  </img>
                    <span style={{color:"white", fontSize:"20px", marginLeft:"20px"}}>Miscellaneous</span>
                
                <Dropdown  overlay={menu} style={{color: "white",fontSize: "20px", marginLeft: "auto"}}>
                  <Button style={{background: "none", border: "none", fontSize: "20px", float: "right", marginTop: "5px"}}>
                  <UserOutlined style={{color: "white" }}>
                    </UserOutlined>
                  </Button>
                </Dropdown>
                </div>
                
              </Header>
              <ul style={{margin: "auto", width: "65%", background: "yellow", color:"red", fontSize: "20px", textAlign:"center"}}>
                      <h6 style={{color:"red", fontSize: "20px"}}>Information Notice:</h6> 
                    <li>If status is "In review" please wait until 25th of the month for reimbursements to be credited</li>
                    <li>If status remains "Approved" after 25th of the month, please contact admin@newgen.co</li>
                    
                  </ul> 
              <Layout className="site-layout">
              
              <Content
                  className="site-layout-background"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "24px 16px",
                    padding: 24,
                    minHeight: "100vh",
                  }}
                >
                  <div className="domainmail-continer" style={{ width: "100%" }}>
              
                    <ReimbursementsHeader></ReimbursementsHeader>
                    <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>It looks like you have not added your bank details.</p>
                <p>Please do so here: </p>
                <p>https://misc.newgen.co/user/preferences</p>
              </Modal>
                    <Form form={form} layout="vertical" name="searchForm" onFinish={searchReimbursements} initialValues={{["year"]: (new Date().getFullYear() -1) + "-" + new Date().getFullYear(), ["genre"]: "Internet"}}>
                  <div style={{display:"flex", flexDirection: "row"}}>
                        <div class="reimbursement-category">
                        <label>Select Period:</label>
                        <Form.Item name="year">
                        <Select placeholder="Select a year" defaultValue={new Date().getFullYear() + "-" + (new Date().getFullYear()+1)}>
                        {/* <Select.Option value={(new Date().getFullYear()+1) + " - " + (new Date().getFullYear()+2)}>{(new Date().getFullYear()+1) + " - " + (new Date().getFullYear()+2)}</Select.Option> */}
                            <Select.Option value={new Date().getFullYear() + "-" + (new Date().getFullYear()+1)}>{new Date().getFullYear() + " - " + (new Date().getFullYear()+1)}</Select.Option>
                            <Select.Option value={(new Date().getFullYear() -1) + "-" + new Date().getFullYear()}>{(new Date().getFullYear() -1) + " - " + new Date().getFullYear()}</Select.Option>
                        </Select>
                        </Form.Item>
                        
                        </div>
                        <div class="reimbursement-category">
                        <label>Select Category:</label>
                        <Form.Item name="genre">
                        <Select placeholder="Select a category" defaultValue={"Internet"}>
                            <Select.Option value="Internet">Internet</Select.Option>
                        </Select>
                        </Form.Item>
                        </div>
                        <div class="reimbursement-category">
                        <label>Search</label>
                        <Button htmlType="submit" type='primary'>Go</Button>
                        </div>
                        {userAccess === "admin" ? <div class="reimbursement-category">
                        <label>View as Admin</label>
                        <Switch style={{maxWidth: "20px", marginTop: "5px"}} onChange={(e)=> reRenderAdmin(e)}/>
                        </div>: ""}
                    </div>
                  </Form>
                      <div class="reimbursement-visuals">
                      <div class="bar-container">
                      <Bar data={barData}></Bar>
                      </div>
                      <div class="doughnut-container">
                      <Doughnut data={doughData} options={{
                plugins: {
                  tooltip: {
                    enabled: false
                  }
                },
                rotation: -90,
                circumference: 180,
                cutout: "60%",
                maintainAspectRatio: true,
                responsive: true,
                backgroundColor: "white",
                  hoverBackgroundColor: "white",
              }}></Doughnut>
                      </div>
                      
                      </div>
                      <div style={{padding: "50px", display: "flex", justifyContent:"center"}}>
                      <Table dataSource={dataSource} columns={columns} style={{border:"1px solid #cccccc"}} pagination={false} summary={pageData => {
        let totalClaimed = 0;
        let totalActual = 0;

        pageData.forEach(({ claim, actual }) => {
          totalClaimed += claim;
          totalActual += actual;
        });

        return (
          <>
            <Table.Summary.Row>
            
              <Table.Summary.Cell><Text style={{color:"#fc7008", fontWeight: 600}}>Total</Text></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text style={{color:"blue", fontWeight: 600}}>{Math.round(totalActual)}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text style={{color:"blue", fontWeight: 600}}>{Math.round(totalClaimed)}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
            <Table.Summary.Cell></Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}/>
                      </div>
                      
                      <ol style={{margin: "auto", width: "65%"}}>
                      <h6 style={{color: "blue"}}>Information on reimbursements:</h6> 
                    <li>All internet reimbursement bills must be submitted exclusively through this portal or before the 15th of each month.</li>
                    <li>Previous finance year bills will not be accepted or reimbursed.</li>
                    <li>You can upload bills for future-dated reimbursements. For instance, if you have a 6-month data plan, you may upload the same bill for all 6 months. However, reimbursements will be processed monthly, meaning your 6-month plan will be reimbursed in 6 separate instalments.
                    If you have any questions or concerns, please consult your manager first.</li>
                    <li>Upload the bills against the specific month. For example, if you receive a bill for November month consumption in the first week of December, upload the bill against November.</li>
                    <li>The accounts team processes reimbursement payments on the 15th and 30th of every month. Bills submitted on or before the 5th will be reimbursed by the 15th, while bills submitted after the 5th are paid by the 30th.</li>
                  </ol>   
                  
                  
                  
                  </div>
                  
                  </Content>
              </Layout>
              
            </Layout>
          );
    }
    else{
        return(<Layout>
            {contextHolder}
            <Header
                className="site-layout-background"
                style={{
                  padding: 0,
                }}
              >
                <div>
                  <img src={newgenLogo} style={{maxHeight:"40px", marginLeft:"20px"}}>

                  </img>
                  
                    <span style={{color:"white", fontSize:"20px", marginLeft:"20px"}}>Miscellaneous</span>
                  
                    <Dropdown  overlay={menu} style={{color: "white",fontSize: "20px", marginLeft: "auto"}}>
                  <Button style={{background: "none", border: "none", fontSize: "20px", float: "right", marginTop: "5px"}}>
                  <UserOutlined style={{color: "white" }}>
                    </UserOutlined>
                  </Button>
                </Dropdown>
                </div>
              </Header>
            <Layout className="site-layout">
            <Content
                className="site-layout-background"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "24px 16px",
                  padding: 24,
                  minHeight: "100vh",
                }}
              >
                <div className="domainmail-continer" style={{ width: "100%" }}>
                  <ReimbursementsHeader></ReimbursementsHeader>
                  <Form form={form} layout="vertical" name="searchForm" onFinish={searchReimbursements}
                  initialValues={{["year"]: (new Date().getFullYear() -1) + "-" + new Date().getFullYear(),["month"]: monthList[monthList.findIndex((e=> { return e == monthListRegular[new Date().getMonth()]}))] ,["genre"]: "Internet"}}>
                  <div style={{display:"flex", flexDirection: "row"}}>
                        <div class="reimbursement-category">
                        <label>Select Period:</label>
                        <Form.Item name="year">
                        <Select placeholder="Select a year" defaultValue={(new Date().getFullYear() -1) + " - " + new Date().getFullYear()}>
                        <Select.Option value={(new Date().getFullYear()+1) + "-" + (new Date().getFullYear()+2)}>{(new Date().getFullYear()+1) + " - " + (new Date().getFullYear()+2)}</Select.Option>
                            <Select.Option value={new Date().getFullYear() + "-" + (new Date().getFullYear()+1)}>{new Date().getFullYear() + " - " + (new Date().getFullYear()+1)}</Select.Option>
                            <Select.Option value={(new Date().getFullYear() -1) + "-" + new Date().getFullYear()}>{(new Date().getFullYear() -1) + " - " + new Date().getFullYear()}</Select.Option>
                        </Select>
                        </Form.Item>
                        
                        </div>
                        <div class="reimbursement-category">
                          <label>Select Month:</label>
                          <Form.Item name="month" >
                          <Select placeholder="Select a month" defaultValue={"Dec"} options={months}>
                          </Select>
                          </Form.Item>
                          
                          </div>
                        <div class="reimbursement-category">
                        <label>Select Category:</label>
                        <Form.Item name="genre">
                        <Select placeholder="Select a category" defaultValue={"Internet"}>
                            <Select.Option  value="Internet">Internet</Select.Option>
                        </Select>
                        </Form.Item>
                        </div>
                        <div class="reimbursement-category">
                        <label>Search</label>
                        <Button htmlType="submit" type='primary'>Go</Button>
                        </div>
                        <div class="reimbursement-category">
                        <label>View as Employee</label>
                        <Switch style={{maxWidth: "20px", marginTop: "5px"}} onChange={(e)=> reRender(e)}/>
                        </div>
                    </div>
                  </Form>
                    
                    <div class="reimbursement-visuals">
                    <div class="bar-container">
                    <Bar data={barData}></Bar>
                    </div>
                    <div class="doughnut-container">
                    <Doughnut data={doughData} options={{
              plugins: {
                tooltip: {
                  enabled: false
                }
              },
              rotation: -90,
              circumference: 180,
              cutout: "60%",
              maintainAspectRatio: true,
              responsive: true,
              backgroundColor: "white",
                hoverBackgroundColor: "white",
            }}></Doughnut>
                    </div>
                    </div>
                    <div style={{padding: "50px"}}>
                    <Table dataSource={dataSource} columns={adminColumns} style={{border:"1px solid #cccccc"}} pagination={false} summary={pageData => {
        let totalClaimed = 0;
        let totalActual = 0;

        pageData.forEach(({ claim, actual }) => {
          totalClaimed += claim;
          totalActual += actual;
        });

        return (
          <>
            <Table.Summary.Row>
            <Table.Summary.Cell></Table.Summary.Cell>
            <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell><Text style={{color:"#fc7008", fontWeight: 600}}>Total</Text></Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text style={{color:"blue", fontWeight: 600}}>{Math.round(totalClaimed)}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text style={{color:"blue", fontWeight: 600}}>{Math.round(totalActual)}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
            <Table.Summary.Cell></Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}/>
      <h6 style={{marginTop: "20px"}}>Reimbursement Transfers</h6>
      <Table dataSource={transferDataSource} columns={transferColumns} style={{border:"1px solid #cccccc"}} pagination={false}>

      </Table>
                    </div>
                    <Modal
          visible={updateStatusModalVisible}
          title= {"Are you sure?"}
          onOk={(e) => updateStatusforApplication(e)}
          onCancel={()=> cancelUpdateModal()}
          footer={[
            <Button key="back" onClick={()=> cancelUpdateModal()}>
              Return
            </Button>,
            <Button key="submit" type="primary" onClick={(e) => updateStatusforApplication(e)}>
              Submit
            </Button>
          ]}
        >
            {`You are about to approval ` + selectedToUpdate }
        </Modal>
                    <Button style={{backgroundColor: "#white", color: "#fc7008", marginLeft: "50px", fontSize: 15, textAlign:"center", verticalAlign:"middle"}}>Pay Now</Button>    
                </div>
                
                </Content>
            </Layout>
            
          </Layout>);
    }
    
};